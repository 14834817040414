import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/firebase-config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { Form, Button, Card, Alert, Image } from 'react-bootstrap';
import { useUser } from '../../context/UserContext'; // Import the UserContext
import User from '../../models/User'; // Import the updated User model
import './Login.css';  // Import custom CSS for additional styling

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useUser();  // Get the setUser function from the context

  // Function to create a new user profile or update the existing one if needed
  const createOrFetchProfile = async (uid, email) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      // Create a new user profile using the User model
      const newUserProfile = new User({
        uid,
        firstName: "",
        lastName: "",
        displayName: "",
        email, // Store the email from the authentication
        role: "user",
        photoURL: "path_to_default_profile_image.png",
        unit: "",
        phone: ""
      });

      // Store the new user profile in Firestore
      await setDoc(docRef, newUserProfile.toFirestore());
      return newUserProfile;
    } else {
      // If the user profile exists, check if the email is missing
      const userData = docSnap.data();
      if (!userData.email) {
        // Update the user document to include the email if it is missing
        await updateDoc(docRef, { email });
      }

      // Return the existing user profile
      return User.fromFirestore(docSnap);
    }
  };

  // Handle login process
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;

      // Fetch or create the user profile, passing the user's email
      const userProfile = await createOrFetchProfile(firebaseUser.uid, firebaseUser.email);

      // Set the user in the global UserContext
      setUser(userProfile);

      console.log('Login successful, navigating to dashboard');
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <Card.Body>
          <div className="text-center mb-4">
            <Image src="/sindikat-triglav-logo.png" alt="Logo" className="login-logo" />
          </div>
          <Card.Title className="mb-4 text-center">Prijava</Card.Title>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email naslov</Form.Label>
              <Form.Control
                type="email"
                placeholder="Vnesi email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Geslo</Form.Label>
              <Form.Control
                type="password"
                placeholder="Geslo"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="danger" type="submit" className="w-100" disabled={loading}>
              {loading ? 'Nalagam...' : 'Prijava'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Login;
