import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebase/firebase-config';
import { collection, addDoc, doc, updateDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Button, Form, Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './NoticeCreate.css';
import { useUser } from '../../context/UserContext';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
};

function NoticeCreate({ onNoticeCreated, noticeToEdit }) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [unit, setUnit] = useState('general');
  const [unitName, setUnitName] = useState('Splošno');
  const [units, setUnits] = useState([]);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [thumbnail, setThumbnail] = useState(null); // New state for thumbnail
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { user } = useUser();

  useEffect(() => {
    if (noticeToEdit) {
      setTitle(noticeToEdit.title);
      setText(noticeToEdit.text);
      setUnit(noticeToEdit.unit);
      setUnitName(noticeToEdit.unitName);
      setFileUrl(noticeToEdit.fileUrl || '');
    }
  }, [noticeToEdit]);

  useEffect(() => {
    const fetchUnits = async () => {
      const unitsCollectionRef = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollectionRef);
      const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUnits(unitsList);
    };

    fetchUnits();
  }, []);

  const handleUnitChange = (e) => {
    const selectedUnitId = e.target.value;
    const selectedUnit = units.find(unit => unit.id === selectedUnitId);
    setUnit(selectedUnitId);
    setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
  };

  const handleThumbnailChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage && !selectedImage.type.startsWith('image/')) {
      setError("Only image files are allowed for the thumbnail.");
      setThumbnail(null);
    } else {
      setError('');
      setThumbnail(selectedImage);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a notice.');
      return;
    }
    if (!title.trim() || !text.trim()) {
      setError('Title and text cannot be empty.');
      return;
    }

    let fileDownloadUrl = fileUrl;

    // Upload main file if provided
    if (file) {
      setUploading(true);
      try {
        const storageRef = ref(storage, `notices/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              console.error('Upload error:', error);
              setError('Failed to upload file. Please try again.');
              setUploading(false);
              reject(error);
            },
            async () => {
              fileDownloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } catch (error) {
        // Error handled above
        return;
      }
    }

    // Upload thumbnail if provided
    let thumbnailUrl = '';
    if (thumbnail) {
      setUploading(true);
      try {
        const thumbnailRef = ref(storage, `notice-thumbnails/${thumbnail.name}`);
        const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, thumbnail);

        await new Promise((resolve, reject) => {
          thumbnailUploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              console.error('Thumbnail upload error:', error);
              setError('Failed to upload thumbnail. Please try again.');
              setUploading(false);
              reject(error);
            },
            async () => {
              thumbnailUrl = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } catch (error) {
        // Error handled above
        return;
      }
    }

    saveNotice(fileDownloadUrl, thumbnailUrl);
  };

  const saveNotice = async (fileDownloadUrl, thumbnailUrl) => {
    const newNotice = {
      title,
      text,
      unit,
      unitName,
      fileUrl: fileDownloadUrl || '',
      thumbnailUrl: thumbnailUrl || ''
    };

    if (!noticeToEdit) {
      newNotice.createdAt = new Date();
      newNotice.uid = user.uid;
      newNotice.displayName = user.displayName || 'Anonymous';
    }

    try {
      if (noticeToEdit) {
        await updateDoc(doc(db, 'notices', noticeToEdit.id), newNotice);
      } else {
        await addDoc(collection(db, 'notices'), newNotice);
      }
      setTitle('');
      setText('');
      setUnit('general');
      setUnitName('Splošno');
      setFile(null);
      setFileUrl('');
      setThumbnail(null);
      setError('');
      setUploading(false);
      onNoticeCreated();
    } catch (error) {
      setError('Failed to create or update notice. Please try again.');
      console.error('Error creating or updating notice:', error);
      setUploading(false);
    }
  };

  return (
    <Container className="notice-create-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Naslov</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vpišite naslov"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Besedilo</Form.Label>
              <div className="quill-container">
                <ReactQuill
                  value={text}
                  onChange={setText}
                  placeholder="Vpišite obvestilo"
                  theme="snow"
                  modules={modules}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Enota</Form.Label>
              <Form.Control
                as="select"
                value={unit}
                onChange={handleUnitChange}
              >
                <option value="general">Splošno</option>
                {units
                  .filter(unit => user.role === 'admin' || unit.id === user.unit)
                  .map(unit => (
                    <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        
        {/* File Upload */}
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Datoteka (PDF)</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Thumbnail Upload */}
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Ikona obvestila (thumbnail)</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleThumbnailChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {uploading && (
          <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} striped />
        )}
        <Button variant="danger" type="submit" disabled={uploading}>
          {noticeToEdit ? 'Posodobi obvestilo' : 'Objavi obvestilo'}
        </Button>
      </Form>
    </Container>
  );
}

export default NoticeCreate;
