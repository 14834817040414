import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { addDoc, serverTimestamp } from 'firebase/firestore';
import { useUser } from '../../context/UserContext'; // Use UserContext
import QnAMessage from '../../models/QnAMessage';  // Import the QnAMessage model
import './QnAMessageForm.css';

function QnAMessageForm({ messagesCollectionRef }) {
    const [newMessage, setNewMessage] = useState('');
    const { user } = useUser(); // Get the current user from context

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            // Create a new instance of QnAMessage using the model
            const message = new QnAMessage({
                text: newMessage,
                uid: user.uid,
                displayName: user.displayName,
                email: user.email,
                createdAt: serverTimestamp() // Use server timestamp
            });

            // Save the message to Firestore using the model's toFirestore method
            await addDoc(messagesCollectionRef, message.toFirestore());

            setNewMessage(''); // Clear the input after sending
        }
    };

    return (
        <Form onSubmit={handleSendMessage} className="message-form">
            <Form.Group className="mb-3" controlId="formMessage">
                <Form.Control
                    type="text"
                    placeholder="Vnesi sporočilo..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    required
                />
            </Form.Group>
            <Button variant="danger" type="submit" className="w-100">
                Pošlji
            </Button>
        </Form>
    );
}

export default QnAMessageForm;
