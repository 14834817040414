import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import User from '../models/User';  // Import the User model

// Create the UserContext
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// UserProvider component to wrap the app and provide the user state
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profileComplete, setProfileComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthState = async (authUser) => {
      if (authUser) {
        const docRef = doc(db, "users", authUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userProfile = User.fromFirestore(docSnap);  // Use the User model

          // Check if profile is complete
          setProfileComplete(
            userProfile.displayName && userProfile.firstName && userProfile.lastName && userProfile.phone
          );
          
          setUser(userProfile);
        }
      } else {
        setUser(null);
        setProfileComplete(false);
      }
      setLoading(false);
    };

    // Listen to auth state changes
    const unsubscribe = onAuthStateChanged(auth, checkAuthState);

    return () => unsubscribe();
  }, []);

  // Ensure setUser is passed in the value
  return (
    <UserContext.Provider value={{ user, profileComplete, loading, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
