import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import NoticeCreate from './NoticeCreate';
import NoticeList from './NoticeList';
import NoticeDetail from './NoticeDetail';
import { useUser } from '../../context/UserContext';
import './Notices.css';

function Notices() {
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [showCreateNotice, setShowCreateNotice] = useState(false);
  const [noticeToEdit, setNoticeToEdit] = useState(null); // State for editing notice

  const { user } = useUser();

  const handleSelectNotice = (notice) => {
    setSelectedNotice(notice);
    setShowCreateNotice(false);
    setNoticeToEdit(null); // Clear the edit state when selecting a notice
  };

  const handleBack = () => {
    setSelectedNotice(null);
    setShowCreateNotice(false);
    setNoticeToEdit(null); // Clear the edit state when navigating back
  };

  const toggleCreateNoticeForm = () => {
    setShowCreateNotice(!showCreateNotice);
    setSelectedNotice(null);
    setNoticeToEdit(null); // Clear the edit state when toggling the form
  };

  const handleNoticeCreated = () => {
    setShowCreateNotice(false);
    setSelectedNotice(null);
    setNoticeToEdit(null); // Clear the edit state after creating a notice
  };

  const handleEditNotice = (notice) => {
    setNoticeToEdit(notice); // Set the notice to be edited
    setShowCreateNotice(true); // Show the notice create form in edit mode
    setSelectedNotice(null);
  };

  return (
    <div className="full-bg">
      <Container className="notices-container mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1 className="notices-title">Obvestila</h1>
          </Col>
          <Col className="text-end">
            {!selectedNotice && (user.role === 'admin' || user.role === 'local-admin') && (
              <Button variant={showCreateNotice ? "secondary" : "danger"} onClick={toggleCreateNoticeForm}>
                {showCreateNotice ? 'Nazaj na izbiro' : 'Ustvari novico'}
              </Button>
            )}
            {selectedNotice && (
              <Button variant="secondary" onClick={handleBack}>Nazaj na izbiro</Button>
            )}
          </Col>
        </Row>
        {!selectedNotice ? (
          <>
            {showCreateNotice ? (
              <NoticeCreate onNoticeCreated={handleNoticeCreated} noticeToEdit={noticeToEdit} />
            ) : (
              <NoticeList
                onSelectNotice={handleSelectNotice}
                isAdmin={user && user.role === "admin"}
                onEditNotice={handleEditNotice} // Pass the edit handler to the list
              />
            )}
          </>
        ) : (
          <NoticeDetail notice={selectedNotice} />
        )}
      </Container>
    </div>
  );
}

export default Notices;
