import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import QnACreateQuestion from './QnACreateQuestion';
import QnAList from './QnAList';
import QnAChat from './QnAChat';
import { useUser } from '../../context/UserContext';
import './QnA.css';

function QnA() {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showCreateQuestion, setShowCreateQuestion] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState(null);

    const { user } = useUser();

    const handleSelectQuestion = (question) => {
        setSelectedQuestion(question);
        setShowCreateQuestion(false);
        setEditingQuestion(null);
    };

    const handleBack = () => {
        setSelectedQuestion(null);
        setShowCreateQuestion(false);
        setEditingQuestion(null);
    };

    const toggleCreateQuestionForm = () => {
        setShowCreateQuestion(!showCreateQuestion);
        setSelectedQuestion(null);
        setEditingQuestion(null);
    };

    const handleQuestionCreated = () => {
        setShowCreateQuestion(false);
        setSelectedQuestion(null);
    };

    const handleEditQuestion = (question) => {
        setEditingQuestion(question);
        setShowCreateQuestion(false);
        setSelectedQuestion(null);
    };

    const handleQuestionUpdated = () => {
        setEditingQuestion(null);
        setSelectedQuestion(null);
    };

    return (
        <div className="full-bg">
            <Container className="qna-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="qna-title">Vprašanja</h1>
                    </Col>
                    <Col className="text-end">
                        {!selectedQuestion && !editingQuestion && (
                            <Button variant={showCreateQuestion ? "secondary" : "danger"} onClick={toggleCreateQuestionForm}>
                                {showCreateQuestion ? 'Nazaj na vprašanja' : 'Novo vprašanje'}
                            </Button>
                        )}
                        {(selectedQuestion || editingQuestion) && (
                            <Button variant="secondary" onClick={handleBack}>Nazaj na vprašanja</Button>
                        )}
                    </Col>
                </Row>
                {!selectedQuestion && !editingQuestion ? (
                    <>
                        {showCreateQuestion ? (
                            <QnACreateQuestion onQuestionCreated={handleQuestionCreated} />
                        ) : (
                            <QnAList 
                                onSelectQuestion={handleSelectQuestion}
                                isAdmin={user && user.role === "admin"} 
                                onEditQuestion={handleEditQuestion}
                            />
                        )}
                    </>
                ) : editingQuestion ? (
                    <QnACreateQuestion 
                        onQuestionCreated={handleQuestionCreated} 
                        question={editingQuestion} 
                        onQuestionUpdated={handleQuestionUpdated}
                    />
                ) : (
                    <QnAChat
                        questionId={selectedQuestion.id}
                        questionTitle={selectedQuestion.title}
                        selectedQuestion={selectedQuestion}
                    />
                )}
            </Container>
        </div>
    );
}

export default QnA;
