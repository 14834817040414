import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { db, auth, storage } from "../../firebase/firebase-config"; // Ensure storage is imported
import { Button, Form, Container, Row, Col, Image, Alert, Spinner } from 'react-bootstrap';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import User from "../../models/User"; // Assuming you have a User model

function Profile({ onProfileComplete }) {
  const [profile, setProfile] = useState(new User({}));
  const [editing, setEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [units, setUnits] = useState([]);
  const [profileComplete, setProfileComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userProfile = User.fromFirestore(docSnap); // Use the model to load data
        setProfile(userProfile);
        setIsAdmin(userProfile.role === "admin");
        checkProfileComplete(userProfile); // Check profile completion status
      }
      setLoading(false);
    };

    const fetchUnits = async () => {
      const unitsCollectionRef = collection(db, "units");
      const unitsSnapshot = await getDocs(unitsCollectionRef);
      const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUnits(unitsList);
    };

    fetchProfile();
    fetchUnits();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfile(prev => {
      const updatedProfile = { ...prev, [name]: value };

      // Automatically update displayName based on firstName and lastName
      if (name === 'firstName' || name === 'lastName') {
        updatedProfile.displayName = `${updatedProfile.firstName} ${updatedProfile.lastName}`;
      }

      return new User(updatedProfile); // Ensure it's returned as a User instance
    });
  };

  const checkProfileComplete = (userProfile) => {
    const isComplete = userProfile.firstName && userProfile.lastName && userProfile.phone;
    setProfileComplete(isComplete);
  };

  const handleSave = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    await setDoc(docRef, profile.toFirestore()); // Save the user model to Firestore
    setEditing(false);

    checkProfileComplete(profile);

    // Only call onProfileComplete if it is a function
    if (profileComplete && typeof onProfileComplete === 'function') {
      onProfileComplete();
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(snapshot.ref);
      setProfile(prev => new User({ ...prev, photoURL }));
      const docRef = doc(db, "users", auth.currentUser.uid);
      await setDoc(docRef, profile.toFirestore(), { merge: true }); // Use model to save
    } catch (error) {
      console.error("Failed to upload image:", error);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="full-bg">
      <Container className="mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1>Profil uporabnika</h1>
          </Col>
          <Col className="text-end">
            <Button onClick={() => setEditing(!editing)} variant={editing ? "secondary" : "primary"}>
              {editing ? "Prekliči" : "Uredi profil"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="text-center mb-3">
            <Image
              src={profile.photoURL}
              roundedCircle
              style={{
                width: "300px",
                height: "300px",
                objectFit: "cover"
              }}
            />
            {editing && (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Naloži profilno sliko</Form.Label>
                <Form.Control type="file" onChange={handleImageUpload} />
              </Form.Group>
            )}
          </Col>
          <Col md={8}>
            <Form>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>Ime</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vnesite ime"
                  name="firstName"
                  value={profile.firstName}
                  onChange={handleChange}
                  readOnly={!editing}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Priimek</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vnesite priimek"
                  name="lastName"
                  value={profile.lastName}
                  onChange={handleChange}
                  readOnly={!editing}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Telefonska številka</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vnesite telefonsko številko"
                  name="phone"
                  value={profile.phone}
                  onChange={handleChange}
                  readOnly={!editing}
                  required
                />
              </Form.Group>

              {isAdmin && (
                <Form.Group className="mb-3" controlId="formUnit">
                  <Form.Label>Enota</Form.Label>
                  <Form.Select
                    name="unit"
                    value={profile.unit}
                    onChange={handleChange}
                    disabled={!editing}
                  >
                    <option value="">Izberi enoto</option>
                    {units.map(unit => (
                      <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {isAdmin && editing && (
                <Form.Group className="mb-3" controlId="formRole">
                  <Form.Label>Vloga</Form.Label>
                  <Form.Select
                    name="role"
                    value={profile.role}
                    onChange={handleChange}
                    disabled={!editing}
                  >
                    <option value="user">Uporabnik</option>
                    <option value="admin">Admin</option>
                  </Form.Select>
                </Form.Group>
              )}

              {editing && (
                <Button variant="success" onClick={handleSave}>
                  Shrani
                </Button>
              )}
            </Form>

            {!profileComplete && (
              <Alert variant="warning" className="mt-3">
                <Alert.Heading>Dokončajte svoj profil</Alert.Heading>
                <p>
                  Prosimo, izpolnite vse podatke o profilu, vključno z imenom, priimkom in telefonsko številko, da boste lahko nadaljevali.
                </p>
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}


// Provide a default no-op function so the error doesn't occur if not provided
Profile.defaultProps = {
  onProfileComplete: () => {},
};

export default Profile;
