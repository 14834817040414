import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase-config';
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Button, Form, FormControl, Alert, Container, Row, Col } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import QnAQuestion from '../../models/QnAQuestion';  
import './QnACreateQuestion.css';

function QnACreateQuestion({ onQuestionCreated, question, onQuestionUpdated }) {
    const [questionText, setQuestionText] = useState('');
    const [error, setError] = useState('');
    const [unit, setUnit] = useState('general');
    const [unitName, setUnitName] = useState('Splošno');
    const [units, setUnits] = useState([]);
    const { user } = useUser();

    const isEditing = !!question;

    useEffect(() => {
        const fetchUnits = async () => {
            const unitsCollectionRef = collection(db, 'units');
            const unitsSnapshot = await getDocs(unitsCollectionRef);
            const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            if (user.role !== 'admin') {
                const filteredUnits = unitsList.filter(unit => unit.id === user.unit);
                setUnits([{ id: 'general', unitName: 'Splošno' }, ...filteredUnits]);
            } else {
                setUnits([{ id: 'general', unitName: 'Splošno' }, ...unitsList]);
            }
        };

        fetchUnits();
    }, [user]);

    useEffect(() => {
        if (isEditing && question) {
            // Prefill the fields with existing question data
            setQuestionText(question.title);
            setUnit(question.unit);
            setUnitName(question.unitName);
        }
    }, [isEditing, question]);

    const handleUnitChange = (e) => {
        const selectedUnitId = e.target.value;
        const selectedUnit = units.find(unit => unit.id === selectedUnitId);
        setUnit(selectedUnitId);
        setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            setError("You must be logged in to post a question.");
            return;
        }
        if (!questionText.trim()) {
            setError("The question cannot be empty.");
            return;
        }

        try {
            if (isEditing) {
                // Update existing question
                const questionRef = doc(db, "questions", question.id);
                await updateDoc(questionRef, {
                    title: questionText,
                    unit: unit,
                    unitName: unitName,
                });
                setError('');
                if (onQuestionUpdated) onQuestionUpdated();
            } else {
                // Create a new question
                const newQuestion = new QnAQuestion({
                    title: questionText,
                    uid: user.uid,
                    displayName: user.displayName,
                    unit: unit,
                    unitName: unitName,
                    createdAt: new Date()
                });

                await addDoc(collection(db, "questions"), newQuestion.toFirestore());
                setQuestionText('');
                setUnit('general');
                setUnitName('Splošno');
                setError('');
                onQuestionCreated();
            }

        } catch (err) {
            setError("Failed to save the question. Please try again.");
            console.error("Error saving question:", err);
        }
    };

    return (
        <Container className="create-question-container">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Vprašanje</Form.Label>
                            <FormControl
                                type="text"
                                placeholder="Vnesi vprašanje"
                                value={questionText}
                                onChange={(e) => setQuestionText(e.target.value)}
                                isInvalid={!!error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Enota</Form.Label>
                            <Form.Control
                                as="select"
                                value={unit}
                                onChange={handleUnitChange}
                            >
                                {units.map(unit => (
                                    <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" type="submit" className="w-100">
                    {isEditing ? 'Posodobi' : 'Pošlji'}
                </Button>
            </Form>
        </Container>
    );
}

export default QnACreateQuestion;
