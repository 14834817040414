import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { addDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../../firebase/firebase-config';
import './ChatMessageForm.css';

function ChatMessageForm({ messagesCollectionRef, unit }) {
    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            await addDoc(messagesCollectionRef, {
                text: newMessage,
                createdAt: serverTimestamp(),
                uid: auth.currentUser.uid,
                displayName: auth.currentUser.displayName,
                email: auth.currentUser.email,
                unit
            });
            setNewMessage('');
        }
    };

    return (
        <Form onSubmit={handleSendMessage} className="message-form">
            <Form.Group className="mb-3" controlId="formMessage">
                <Form.Control
                    type="text"
                    placeholder="Vaše sporočilo..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    required
                />
            </Form.Group>
            <Button variant="danger" type="submit" className="w-100">
                Pošlji
            </Button>
        </Form>
    );
}

export default ChatMessageForm;
