import React, { useState, Suspense, lazy } from 'react';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import './Sindikalist.css';

const SindikalistCreate = lazy(() => import('./SindikalistCreate'));
const SindikalistList = lazy(() => import('./SindikalistList'));
const SindikalistDetail = lazy(() => import('./SindikalistDetail'));

function Sindikalist() {
    const [selectedSindikalist, setSelectedSindikalist] = useState(null);
    const [showCreateSindikalist, setShowCreateSindikalist] = useState(false);

    const { user } = useUser();
    const isAdmin = user?.role === 'admin'; // Both admin and local-admin can create documents

    const handleSelectSindikalist = (sindikalist) => {
        setSelectedSindikalist(sindikalist);
        setShowCreateSindikalist(false);
    };

    const handleBack = () => {
        setSelectedSindikalist(null);
        setShowCreateSindikalist(false);
    };

    const toggleCreateSindikalistForm = () => {
        setShowCreateSindikalist(!showCreateSindikalist);
        setSelectedSindikalist(null);
    };

    const handleSindikalistCreated = () => {
        setShowCreateSindikalist(false);
        setSelectedSindikalist(null);
    };

    return (
        <div className="full-bg">
            <Container className="sindikalist-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="sindikalist-title">Sindikalist</h1>
                    </Col>
                    <Col className="text-end">
                        {!selectedSindikalist && isAdmin && (
                            <Button variant={showCreateSindikalist ? "secondary" : "danger"} onClick={toggleCreateSindikalistForm}>
                                {showCreateSindikalist ? 'Nazaj na izbiro' : 'Naloži dokument'}
                            </Button>
                        )}
                        {selectedSindikalist && (
                            <Button variant="secondary" onClick={handleBack}>Nazaj na izbiro</Button>
                        )}
                    </Col>
                </Row>
                <Suspense fallback={<Spinner animation="border" />}>
                    {!selectedSindikalist ? (
                        showCreateSindikalist ? (
                            <SindikalistCreate onSindikalistCreated={handleSindikalistCreated} />
                        ) : (
                            <SindikalistList onSelectSindikalist={handleSelectSindikalist} isAdmin={isAdmin} />
                        )
                    ) : (
                        <SindikalistDetail sindikalist={selectedSindikalist} />
                    )}
                </Suspense>
            </Container>
        </div>
    );
}

export default Sindikalist;
