import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase-config';
import { collection, query, orderBy, doc, onSnapshot, getDocs, deleteDoc, limit, startAfter } from 'firebase/firestore';
import { ListGroup, Button, Row, Col, Badge } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import './ContactList.css';
import { useUser } from '../../context/UserContext';
import Contact from '../../models/Contact'; // Import the Contact model

const CONTACTS_LIMIT = 20;

function ContactList({ isAdmin, onEditContact }) {
  const [contacts, setContacts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const contactsContainerRef = useRef(null);

  const { user } = useUser();

  useEffect(() => {
    const fetchContacts = async () => {
      const q = query(collection(db, 'contacts'), orderBy('createdAt', 'desc'), limit(CONTACTS_LIMIT));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const contactsData = querySnapshot.docs.map((doc) => Contact.fromFirestore(doc));

        setContacts(contactsData);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      });

      return () => unsubscribe();
    };

    fetchContacts();
  }, []);

  const loadMoreContacts = async () => {
    if (!lastVisible || loadingMore) return;

    setLoadingMore(true);
    const q = query(
      collection(db, 'contacts'),
      orderBy('createdAt', 'desc'),
      startAfter(lastVisible),
      limit(CONTACTS_LIMIT)
    );
    const querySnapshot = await getDocs(q);
    const moreContacts = querySnapshot.docs.map((doc) => Contact.fromFirestore(doc));

    setContacts((prevContacts) => [...prevContacts, ...moreContacts]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoadingMore(false);
  };

  const handleDelete = async (contactId) => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      await deleteDoc(doc(db, 'contacts', contactId));
    }
  };

  return (
    <div className="contact-list-container" ref={contactsContainerRef} onScroll={loadMoreContacts}>
      <ListGroup className="contact-list">
        {contacts.map((contact) => (
          <ListGroup.Item key={contact.id} className="contact-list-item">
            <Row>
              <Col md={12} className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{contact.name}</div>
                  <small>
                    Email: <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </small>
                  <br />
                  <small>
                    Telefon: <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                  </small>
                  <br />
                  {/* Display the unit name */}
                  <small className="text-muted">
                    Enota: {contact.unitName}
                  </small>
                </div>
                <small className="text-muted ms-2">{new Date(contact.createdAt).toLocaleString()}</small>
              </Col>
            </Row>
            {isAdmin && (
              <Row>
                <Col md={12} className="d-flex ms-2 my-2">
                  <Button variant="outline-primary" size="sm" onClick={() => onEditContact(contact)} className="me-2">
                    <FaEdit /> Uredi
                  </Button>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDelete(contact.id)}>
                    <FaTrash /> Izbriši
                  </Button>
                </Col>
              </Row>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default ContactList;
