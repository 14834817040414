import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Spinner, Card, ListGroup } from 'react-bootstrap';
import { db, auth } from '../../firebase/firebase-config';
import { doc, collection, addDoc, getDocs } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { useUser } from '../../context/UserContext';
import * as XLSX from 'xlsx';
import './PollDetails.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function PollDetails({ poll, isAdmin, onEditPoll, onBackToList }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [answers, setAnswers] = useState({});
    const [results, setResults] = useState({});
    const [voters, setVoters] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [userHasVoted, setUserHasVoted] = useState(false);
    const [loading, setLoading] = useState(true);

    const { user } = useUser();

    const fetchData = async () => {
        const pollRef = doc(db, "polls", poll.id);
        const answersRef = collection(pollRef, "answers");
        const querySnapshot = await getDocs(answersRef);
        const voteCounts = {};
        const votersList = [];
        let voted = false;

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (!poll.anonymous) {
                const fName = data.firstName || '';
                const lName = data.lastName || '';
                const voterName = (fName.trim() || lName.trim()) 
                    ? `${fName} ${lName}`.trim() 
                    : (data.displayName || 'Anonymous');
                votersList.push({
                    displayName: voterName,
                    userId: data.userId,
                });
            } else {
                votersList.push({ displayName: 'Anonymous', userId: null });
            }

            data.answers.forEach((answer, index) => {
                voteCounts[index] = voteCounts[index] || {};
                if (Array.isArray(answer.answer)) {
                    answer.answer.forEach(ans => {
                        voteCounts[index][ans] = (voteCounts[index][ans] || 0) + 1;
                    });
                } else {
                    voteCounts[index][answer.answer] = (voteCounts[index][answer.answer] || 0) + 1;
                }
            });

            if (!poll.anonymous && data.userId === auth.currentUser.uid) {
                voted = true;
            }
        });

        setResults(voteCounts);
        setVoters(votersList);
        if (!poll.anonymous) {
            setUserHasVoted(voted);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poll.id]);

    const handleExportToExcel = () => {
        const data = poll.questions.map((question, index) => {
            const questionResults = results[index] || {};
            return {
                Vprašanje: question.question,
                ...Object.keys(questionResults).reduce((acc, option) => {
                    acc[option] = questionResults[option];
                    return acc;
                }, {})
            };
        });

        const votersData = voters.map(voter => ({ Ime: voter.displayName }));

        const worksheet1 = XLSX.utils.json_to_sheet(data);
        const worksheet2 = XLSX.utils.json_to_sheet(votersData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Rezultati");
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Seznam glasovalcev");

        XLSX.writeFile(workbook, `Anketa_${poll.title.replace(/\s+/g, '_')}_Rezultati_Glasovalci.xlsx`);
    };

    const handleAnswerChange = (index, value) => {
        setAnswers({ ...answers, [index]: value });
    };

    const handleCheckboxChange = (index, value, checked) => {
        const currentAnswers = answers[index] || [];
        if (checked) {
            handleAnswerChange(index, [...currentAnswers, value]);
        } else {
            handleAnswerChange(index, currentAnswers.filter(a => a !== value));
        }
    };

    const handleSubmit = async () => {
        if (Object.keys(answers).length < poll.questions.length) {
            alert('Please answer all questions.');
            return;
        }

        if (!userHasVoted && poll.active) {
            setLoading(true);
            const pollRef = doc(db, "polls", poll.id);
            const answersRef = collection(pollRef, "answers");

            const voteData = {
                answers: Object.entries(answers).map(([index, answer]) => ({ questionIndex: Number(index), answer })),
                createdAt: new Date()
            };

            if (!poll.anonymous) {
                voteData.userId = auth.currentUser.uid;
                voteData.firstName = user.firstName || '';
                voteData.lastName = user.lastName || '';
                voteData.displayName = user.displayName || "Anonymous";
            }

            try {
                await addDoc(answersRef, voteData);
                setUserHasVoted(true);
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
                fetchData();
            } catch (error) {
                console.error('Error submitting vote:', error);
                alert('Failed to submit vote. Please try again.');
                setLoading(false);
            }
        }
    };

    const currentQuestion = poll.questions[currentPage];

    const renderQuestion = (question, index) => {
        switch (question.type) {
            case 'radio':
                return (
                    <>
                        {question.options.map((option, oIndex) => (
                            <Form.Check
                                type="radio"
                                label={option}
                                name={`question-${index}`}
                                key={oIndex}
                                checked={answers[index] === option}
                                onChange={() => handleAnswerChange(index, option)}
                                disabled={userHasVoted || !poll.active}
                            />
                        ))}
                        {question.allowCustomAnswer && (
                            <Form.Control
                                type="text"
                                placeholder="Other:"
                                value={answers[index] && !question.options.includes(answers[index]) ? answers[index] : ''}
                                onChange={(e) => handleAnswerChange(index, e.target.value)}
                                disabled={userHasVoted || !poll.active}
                            />
                        )}
                    </>
                );
            case 'checkbox':
                return (
                    <>
                        {question.options.map((option, oIndex) => (
                            <Form.Check
                                type="checkbox"
                                label={option}
                                name={`question-${index}`}
                                key={oIndex}
                                checked={answers[index] && answers[index].includes(option)}
                                onChange={(e) => handleCheckboxChange(index, option, e.target.checked)}
                                disabled={userHasVoted || !poll.active}
                            />
                        ))}
                        {question.allowCustomAnswer && (
                            <Form.Control
                                type="text"
                                placeholder="Other:"
                                onChange={(e) => {
                                    const currentAnswers = answers[index] || [];
                                    handleAnswerChange(index, [...currentAnswers.filter(a => !question.options.includes(a)), e.target.value]);
                                }}
                                disabled={userHasVoted || !poll.active}
                            />
                        )}
                    </>
                );
            case 'text':
                return (
                    <Form.Control
                        type="text"
                        placeholder="Your answer"
                        value={answers[index] || ''}
                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                        disabled={userHasVoted || !poll.active}
                    />
                );
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: 200 }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Nalagam...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <>
            <Card className="poll-card">
                <h4 className='mb-5'>{poll.title}</h4>
                {showAlert && <Alert variant="info" className="mt-2">Hvala za oddan glas!</Alert>}

                {!userHasVoted && poll.active && (
                    <Form>
                        <h5>{currentQuestion.question}</h5>
                        {renderQuestion(currentQuestion, currentPage)}
                        <div className="d-flex justify-content-between mt-3">
                            <Button
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 0}
                            >
                                Nazaj
                            </Button>
                            {currentPage < poll.questions.length - 1 ? (
                                <Button
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={!answers[currentPage]}
                                >
                                    Naprej
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleSubmit}
                                    disabled={!answers[currentPage]}
                                >
                                    Potrdi
                                </Button>
                            )}
                        </div>
                    </Form>
                )}

                {(userHasVoted || !poll.active) && (
                    <Alert variant="info" className="mt-2">Hvala za oddan glas!</Alert>
                )}
            </Card>

            {isAdmin && (
                <>
                    <Card className="poll-card my-5">
                        <h5>Rezultati ankete</h5>
                        <div style={{ height: '400px', overflowY: 'auto' }}>
                            {poll.questions.map((question, index) => (
                                <div key={index} style={{ marginBottom: '20px' }}>
                                    <h5>{question.question}</h5>
                                    <div style={{ height: '200px', overflowY: 'auto' }}>
                                        <Bar
                                            data={{
                                                labels: Object.keys(results[index] || {}),
                                                datasets: [{
                                                    label: 'Votes',
                                                    data: Object.values(results[index] || {}),
                                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                    borderColor: 'rgba(53, 162, 235, 1)',
                                                    borderWidth: 1,
                                                }]
                                            }}
                                            options={{
                                                indexAxis: 'y',
                                                scales: { x: { beginAtZero: true } },
                                                maintainAspectRatio: false
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>

                    <Card className="poll-card mb-5">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Seznam glasovalcev</h5>
                            <Button variant="primary" onClick={handleExportToExcel}>
                                Izvozi rezultate v Excel
                            </Button>
                        </div>
                        <ListGroup variant="flush">
                            {voters.length > 0 ? (
                                voters.map((voter, index) => (
                                    <ListGroup.Item key={index}>
                                        {voter.displayName}
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <ListGroup.Item>Ni glasovalcev.</ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card>
                </>
            )}
        </>
    );
}

export default PollDetails;
