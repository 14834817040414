import React, { useState, useEffect } from 'react';
import { collection, getDoc, getDocs, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { Container, Table, Button, Form, Modal } from 'react-bootstrap';
import { auth, db } from '../../firebase/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import './UserManagement.css';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newUnitName, setNewUnitName] = useState("");
  const [newUnitDescription, setNewUnitDescription] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsAdmin(docSnap.data().role === 'admin');
      }
    };

    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    };

    const fetchUnits = async () => {
      const unitsCollection = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollection);
      const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUnits(unitsList);
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProfile();
        fetchUsers();
        fetchUnits();
      }
    });
  }, []);

  const startEditing = (user) => {
    setEditingUser({ ...user });
  };

  const cancelEditing = () => {
    setEditingUser(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser({ ...editingUser, [name]: value });
  };

  const handleUnitChange = (e) => {
    setEditingUser({ ...editingUser, unit: e.target.value });
  };

  const saveChanges = async () => {
    const userDocRef = doc(db, 'users', editingUser.id);
    await updateDoc(userDocRef, editingUser);
    setUsers(users.map(user => user.id === editingUser.id ? editingUser : user));
    setEditingUser(null);
  };

  const handleAddUnit = async () => {
    const unitsCollection = collection(db, 'units');
    await addDoc(unitsCollection, { unitName: newUnitName, description: newUnitDescription });
    setNewUnitName("");
    setNewUnitDescription("");
    const unitsSnapshot = await getDocs(unitsCollection);
    const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUnits(unitsList);
  };

  const handleDeleteUnit = async (unitId) => {
    if (window.confirm('Ali ste prepričani, da želite izbrisati to enoto?')) {
      await deleteDoc(doc(db, 'units', unitId));
      setUnits(units.filter(unit => unit.id !== unitId));
    }
  };

  if (!isAdmin) {
    return <div>You do not have access to this page.</div>;
  }

  return (
    <div className="full-bg">
      <Container className="user-management-container mt-3">
        <h1 className="user-management-title">Upravljanje</h1>
        <Button variant="primary" onClick={() => setShowModal(true)}>Upravljanje enot</Button>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Email</th>
              <th>Prikazno ime</th>
              <th>Ime</th>
              <th>Priimek</th>
              <th>Telefon</th>
              <th>Enota</th>
              <th>Vloga</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                {editingUser && editingUser.id === user.id ? (
                  <>
                    <td>{user.email}</td>
                    <td>
                      <Form.Control
                        type="text"
                        name="displayName"
                        value={editingUser.displayName}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={editingUser.firstName}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={editingUser.lastName}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={editingUser.phone}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="unit"
                        value={editingUser.unit}
                        onChange={handleUnitChange}
                      >
                        {units.map(unit => (
                          <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="role"
                        value={editingUser.role}
                        onChange={handleInputChange}
                      >
                        <option value="user">Uporabnik</option>
                        <option value="local-admin">Administrator enote</option>
                        <option value="admin">Administrator</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Button variant="success" onClick={saveChanges}>Shrani</Button>{' '}
                      <Button variant="secondary" onClick={cancelEditing}>Prekliči</Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{user.email}</td>
                    <td>{user.displayName}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.phone}</td>
                    <td>{units.find(unit => unit.id === user.unit)?.unitName || 'N/A'}</td>
                    <td>{user.role}</td>
                    <td>
                      <Button variant="primary" onClick={() => startEditing(user)}>Uredi</Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Upravljanje enot</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Dodaj novo enoto</h5>
            <Form.Group controlId="formNewUnitName">
              <Form.Label>Ime enote</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ime enote"
                value={newUnitName}
                onChange={(e) => setNewUnitName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewUnitDescription" className="mt-3">
              <Form.Label>Opis enote</Form.Label>
              <Form.Control
                type="text"
                placeholder="Opis enote"
                value={newUnitDescription}
                onChange={(e) => setNewUnitDescription(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" className="mt-3" onClick={handleAddUnit}>
              Dodaj enoto
            </Button>
            <hr />
            <h5>Seznam enot</h5>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Ime enote</th>
                  <th>Opis</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {units.map(unit => (
                  <tr key={unit.id}>
                    <td>{unit.unitName}</td>
                    <td>{unit.description}</td>
                    <td>
                      <Button variant="danger" onClick={() => handleDeleteUnit(unit.id)}>Izbriši</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Zapri</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default UserManagement;
