class Contact {
    constructor({ id, name, email, phone, unit, unitName, uid, createdAt }) {
      this.id = id || null;
      this.name = name || '';
      this.email = email || '';
      this.phone = phone || '';
      this.unit = unit || 'general';
      this.unitName = unitName || 'Splošno';
      this.uid = uid || '';
      this.createdAt = createdAt || new Date();
    }
  
    // Convert to Firestore format
    toFirestore() {
      return {
        name: this.name,
        email: this.email,
        phone: this.phone,
        unit: this.unit,
        unitName: this.unitName,
        uid: this.uid,
        createdAt: this.createdAt,
      };
    }
  
    // Create instance from Firestore document
    static fromFirestore(snapshot) {
      const data = snapshot.data();
      return new Contact({
        id: snapshot.id,
        name: data.name,
        email: data.email,
        phone: data.phone,
        unit: data.unit || 'general',
        unitName: data.unitName || 'Splošno',
        uid: data.uid,
        createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
      });
    }
  }
  
  export default Contact;
  