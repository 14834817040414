import React, { useState, useEffect } from 'react';
import { db, storage, auth } from '../../firebase/firebase-config';
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Button, Form, Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BenefitCreate.css';
import { useUser } from '../../context/UserContext';

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['link'],
        ['clean']
    ],
};

function BenefitCreate({ onBenefitCreated, benefit, onBenefitUpdated }) {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [unit, setUnit] = useState('general');
    const [unitName, setUnitName] = useState('Splošno');
    const [units, setUnits] = useState([]);
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [thumbnail, setThumbnail] = useState(null); // New: thumbnail state
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { user } = useUser();
    const isEditing = !!benefit;

    useEffect(() => {
        const fetchUnits = async () => {
            const unitsCollectionRef = collection(db, 'units');
            const unitsSnapshot = await getDocs(unitsCollectionRef);
            const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUnits(unitsList);

            if (user.role === 'local-admin') {
                const userUnit = unitsList.find(u => u.id === user.unit);
                if (userUnit) {
                    setUnit(user.unit);
                    setUnitName(userUnit.unitName);
                }
            }
        };

        fetchUnits();
    }, [user]);

    useEffect(() => {
        if (isEditing && benefit) {
            setTitle(benefit.title);
            setText(benefit.text);
            setUnit(benefit.unit || 'general');
            setUnitName(benefit.unitName || 'Splošno');
            setFileUrl(benefit.fileUrl || '');
        }
    }, [isEditing, benefit]);

    const handleUnitChange = (e) => {
        const selectedUnitId = e.target.value;
        const selectedUnit = units.find(u => u.id === selectedUnitId);
        setUnit(selectedUnitId);
        setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
    };

    const handleThumbnailChange = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage && !selectedImage.type.startsWith('image/')) {
            setError("Only image files are allowed for the thumbnail.");
            setThumbnail(null);
        } else {
            setError('');
            setThumbnail(selectedImage);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!auth.currentUser) {
            setError("You must be logged in to create or edit a benefit.");
            return;
        }
        if (!title.trim() || !text.trim()) {
            setError("Title and text cannot be empty.");
            return;
        }

        setUploading(true);

        try {
            let uploadedFileUrl = fileUrl;
            if (file) {
                uploadedFileUrl = await uploadFile(file, 'benefits');
            }

            let thumbnailUrl = '';
            if (thumbnail) {
                thumbnailUrl = await uploadFile(thumbnail, 'benefit-thumbnails');
            }

            await saveBenefit(uploadedFileUrl, thumbnailUrl);
        } catch (error) {
            console.error("Error uploading:", error);
            setError("Failed to upload. Please try again.");
            setUploading(false);
        }
    };

    const uploadFile = (file, folder) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `${folder}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    console.error('Upload error:', error);
                    setError('Failed to upload file. Please try again.');
                    setUploading(false);
                    reject(error);
                },
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(url);
                }
            );
        });
    };

    const saveBenefit = async (fileDownloadUrl, thumbnailUrl) => {
        try {
            if (isEditing && benefit) {
                const benefitRef = doc(db, 'benefits', benefit.id);
                await updateDoc(benefitRef, {
                    title,
                    text,
                    unit: unit || 'general',
                    unitName,
                    fileUrl: fileDownloadUrl || '',
                    thumbnailUrl: thumbnailUrl || ''
                });
                setError('');
                if (onBenefitUpdated) onBenefitUpdated();
            } else {
                await addDoc(collection(db, 'benefits'), {
                    title,
                    text,
                    unit: unit || 'general',
                    unitName,
                    createdAt: new Date(),
                    uid: auth.currentUser.uid,
                    displayName: user.displayName || 'Anonymous',
                    fileUrl: fileDownloadUrl || '',
                    thumbnailUrl: thumbnailUrl || '',
                    order: Date.now()
                });
                setTitle('');
                setText('');
                setUnit('general');
                setUnitName('Splošno');
                setFile(null);
                setThumbnail(null);
                setFileUrl('');
                setError('');
                onBenefitCreated();
            }
        } catch (error) {
            setError("Failed to save benefit. Please try again.");
            console.error("Error saving benefit:", error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <Container className="benefit-create-container">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Naslov</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Vpišite naslov"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Besedilo</Form.Label>
                            <div className="quill-container">
                                <ReactQuill
                                    value={text}
                                    onChange={setText}
                                    placeholder="Vpišite obvestilo"
                                    theme="snow"
                                    modules={modules}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Enota</Form.Label>
                            <Form.Control
                                as="select"
                                value={unit}
                                onChange={handleUnitChange}
                                disabled={user.role === 'local-admin'}
                            >
                                <option value="general">Splošno</option>
                                {units.map(u => (
                                    <option key={u.id} value={u.id}>{u.unitName}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {/* Thumbnail Upload */}
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Ikona (thumbnail)</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleThumbnailChange}
                            />
                            {isEditing && benefit.thumbnailUrl && !thumbnail && (
                                <div className="mt-2">
                                    <small>Trenutna ikona: <a href={benefit.thumbnailUrl} target="_blank" rel="noopener noreferrer">Ogled</a></small>
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {/* File Upload */}
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Datoteka</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                            {isEditing && fileUrl && !file && (
                                <div className="mt-2">
                                    <small>Trenutna datoteka: <a href={fileUrl} target="_blank" rel="noopener noreferrer">Ogled</a></small>
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                {uploading && (
                    <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} striped />
                )}
                <Button variant="danger" type="submit" disabled={uploading}>
                    {isEditing ? 'Posodobi ugodnost' : 'Objavi ugodnost'}
                </Button>
            </Form>
        </Container>
    );
}

BenefitCreate.defaultProps = {
    onBenefitCreated: () => {},
    onBenefitUpdated: () => {}
};

export default BenefitCreate;
