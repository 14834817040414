import React, { useEffect } from 'react';
import { Card, Button, Image } from 'react-bootstrap';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase-config';
import './BenefitDetail.css';

function BenefitDetail({ benefit }) {
    useEffect(() => {
        const markAsRead = async () => {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            await updateDoc(userDocRef, {
                [`lastRead.benefits.${benefit.id}`]: serverTimestamp()
            });
        };

        markAsRead();
    }, [benefit.id]);

    return (
        <Card className="d-flex flex-column notice-detail-card">
            {/* Display thumbnail if available */}
            {benefit.thumbnailUrl && (
                <div className="text-center mt-3">
                    <Image src={benefit.thumbnailUrl} alt="Benefit Thumbnail" fluid style={{ maxHeight: '200px' }} />
                </div>
            )}
            <Card.Header as="h5" className="notice-detail-card-header">{benefit.title}</Card.Header>
            <Card.Body className="flex-grow-1 notice-detail-card-body">
                <div dangerouslySetInnerHTML={{ __html: benefit.text }} />
                {benefit.fileUrl && (
                    <Button
                        variant="primary"
                        href={benefit.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mt-3"
                    >
                        Prenesi pripeto datoteko
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
}

export default BenefitDetail;
