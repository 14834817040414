import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../../firebase/firebase-config';
import { collection, query, orderBy, onSnapshot, where, getDoc, doc, serverTimestamp, updateDoc, limit, startAfter, getDocs, deleteDoc } from 'firebase/firestore';
import { Card } from 'react-bootstrap';
import Message from '../common/Message';
import ChatMessageForm from '../common/ChatMessageForm';
import './ChatRoom.css';

const MESSAGES_LIMIT = 20;

function ChatRoom({ unitId, unitName }) {
    const [messages, setMessages] = useState([]);
    const [lastReadTimestamp, setLastReadTimestamp] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);  // State to check if the user is an admin
    const messagesEndRef = useRef(null);
    const unreadDividerRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const initialLoadRef = useRef(true);

    const messagesCollectionRef = collection(db, "messages");

    useEffect(() => {
        if (!unitId) return;

        const fetchLastReadTimestamp = async () => {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDocSnap = await getDoc(userDocRef);
            const lastRead = userDocSnap.data().lastRead?.chat || {};
            setLastReadTimestamp(lastRead[unitId]?.toMillis() || 0);

            // Check if the user is an admin
            setIsAdmin(userDocSnap.data().role === 'admin');
        };

        fetchLastReadTimestamp();

        const q = query(messagesCollectionRef, where("unit", "==", unitId), orderBy("createdAt", "desc"), limit(MESSAGES_LIMIT));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    createdAt: data.createdAt ? data.createdAt.toMillis() : null
                };
            });
            setMessages(msgs.reverse());
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

            if (initialLoadRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
                initialLoadRef.current = false;
            }
        });

        return () => unsubscribe();
    }, [unitId]);

    const loadMoreMessages = async () => {
        if (!lastVisible || loadingMore) return;

        setLoadingMore(true);
        const previousScrollHeight = messagesContainerRef.current.scrollHeight;

        const q = query(messagesCollectionRef, where("unit", "==", unitId), orderBy("createdAt", "desc"), startAfter(lastVisible), limit(MESSAGES_LIMIT));
        const querySnapshot = await getDocs(q);
        const moreMessages = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                ...data,
                createdAt: data.createdAt ? data.createdAt.toMillis() : null
            };
        });

        setMessages(prevMessages => [...moreMessages.reverse(), ...prevMessages]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

        setTimeout(() => {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - previousScrollHeight;
            setLoadingMore(false);
        }, 0);
    };

    const handleScroll = () => {
        if (messagesContainerRef.current.scrollTop === 0) {
            loadMoreMessages();
        }
    };

    const handleDeleteMessage = async (messageId) => {
        if (window.confirm('Are you sure you want to delete this message?')) {
            await deleteDoc(doc(messagesCollectionRef, messageId));  // Delete the message from Firestore
        }
    };

    useEffect(() => {
        if (messages.length > 0) {
            if (unreadDividerRef.current) {
                unreadDividerRef.current.scrollIntoView({ behavior: 'auto' });
            } else {
                messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
            }
            setTimeout(() => {
                markAsRead();
            }, 500);
        }
    }, [messages]);

    const markAsRead = async () => {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDocRef, {
            [`lastRead.chat.${unitId}`]: serverTimestamp()
        });
    };

    let dividerShown = false;

    return (
        <Card className="d-flex flex-column chat-card">
            <Card.Header as="h5" className="chat-card-header">{unitName}</Card.Header>
            <Card.Body className="flex-grow-1 overflow-auto chat-card-body" ref={messagesContainerRef} onScroll={handleScroll}>
                {messages.map((msg, index) => {
                    const showDivider = !dividerShown && msg.createdAt && msg.createdAt > lastReadTimestamp && msg.uid !== auth.currentUser.uid;
                    if (showDivider) {
                        dividerShown = true;
                    }
                    return (
                        <React.Fragment key={msg.id}>
                            {showDivider && <div className="unread-divider" ref={unreadDividerRef}>Neprebrana sporočila</div>}
                            <Message message={msg} isAdmin={isAdmin} onDelete={() => handleDeleteMessage(msg.id)} />  {/* Pass onDelete to the Message component */}
                        </React.Fragment>
                    );
                })}
                <div ref={messagesEndRef} />
            </Card.Body>
            <Card.Footer className="p-3 chat-card-footer">
                <ChatMessageForm messagesCollectionRef={messagesCollectionRef} unit={unitId} />
            </Card.Footer>
        </Card>
    );
}

export default ChatRoom;
