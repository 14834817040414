import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../../firebase/firebase-config';
import { collection, addDoc, doc, updateDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Button, Form, InputGroup, FormControl, Container, Row, Col, Alert, Accordion, Card, ProgressBar } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useUser } from '../../context/UserContext';
import './PollCreateForm.css';

function PollCreateForm({ onPollCreated, pollToEdit }) {
    const { user } = useUser();
    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    const [expires, setExpires] = useState('');
    const [error, setError] = useState('');
    const [showResultsAfterVote, setShowResultsAfterVote] = useState(true);
    const [unit, setUnit] = useState('general');
    const [unitName, setUnitName] = useState('Splošno');
    const [units, setUnits] = useState([]);
    const [anonymous, setAnonymous] = useState(false);
    const [thumbnail, setThumbnail] = useState(null); // New state for thumbnail
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        const fetchUnits = async () => {
            const unitsCollectionRef = collection(db, 'units');
            const unitsSnapshot = await getDocs(unitsCollectionRef);
            const allUnits = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            let filteredUnits = allUnits;
            if (user.role === 'local-admin') {
                filteredUnits = allUnits.filter(unit => unit.id === user.unit || unit.id === 'general');
            }

            setUnits(filteredUnits);
        };

        fetchUnits();
    }, [user]);

    useEffect(() => {
        if (pollToEdit) {
            setTitle(pollToEdit.title);
            setQuestions(pollToEdit.questions);
            setShowResultsAfterVote(pollToEdit.showResultsAfterSubmit || true);
            setUnit(pollToEdit.unit || 'general');
            setUnitName(pollToEdit.unitName || 'Splošno');
            setAnonymous(pollToEdit.anonymous || false);

            if (pollToEdit.expires) {
                try {
                    if (pollToEdit.expires.toDate) {
                        setExpires(new Date(pollToEdit.expires.toDate()).toISOString().substring(0, 16));
                    } else if (!isNaN(new Date(pollToEdit.expires))) {
                        setExpires(new Date(pollToEdit.expires).toISOString().substring(0, 16));
                    } else {
                        setExpires('');
                    }
                } catch (error) {
                    console.error('Invalid expires value:', pollToEdit.expires);
                    setExpires('');
                }
            }
        }
    }, [pollToEdit]);

    const addQuestion = () => {
        setQuestions([...questions, { question: '', type: 'radio', options: [''], allowCustomAnswer: false }]);
    };

    const handleQuestionChange = (index, field, value) => {
        const newQuestions = [...questions];
        newQuestions[index][field] = value;
        setQuestions(newQuestions);
    };

    const handleOptionChange = (qIndex, oIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].options[oIndex] = value;
        setQuestions(newQuestions);
    };

    const addOption = (qIndex) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].options.push('');
        setQuestions(newQuestions);
    };

    const removeQuestion = (index) => {
        setQuestions(questions.filter((_, i) => i !== index));
    };

    const handleUnitChange = (e) => {
        const selectedUnitId = e.target.value;
        const selectedUnit = units.find(unit => unit.id === selectedUnitId);
        setUnit(selectedUnitId);
        setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const newQuestions = Array.from(questions);
        const [reorderedQuestion] = newQuestions.splice(result.source.index, 1);
        newQuestions.splice(result.destination.index, 0, reorderedQuestion);
        setQuestions(newQuestions);
    };

    const handleThumbnailChange = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage && !selectedImage.type.startsWith('image/')) {
            setError("Only image files are allowed for the thumbnail.");
            setThumbnail(null);
        } else {
            setError('');
            setThumbnail(selectedImage);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!auth.currentUser) {
            setError("You must be logged in to create or edit a poll.");
            return;
        }
        if (!title.trim()) {
            setError("Please enter a valid poll title.");
            return;
        }
        if (questions.length === 0) {
            setError("Please add at least one question.");
            return;
        }

        const { uid, displayName, email } = auth.currentUser;

        const pollData = {
            title,
            questions,
            uid,
            displayName: user.displayName || user.email || 'Anonymous',
            expires: expires ? new Date(expires) : null,
            active: true,
            createdAt: pollToEdit ? pollToEdit.createdAt || new Date() : new Date(),
            showResultsAfterSubmit: showResultsAfterVote,
            unit,
            unitName,
            anonymous,
        };

        let thumbnailUrl = '';

        if (thumbnail) {
            setUploading(true);
            try {
                const thumbnailRef = ref(storage, `poll-thumbnails/${thumbnail.name}`);
                const uploadTask = uploadBytesResumable(thumbnailRef, thumbnail);

                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                        },
                        (err) => {
                            console.error('Thumbnail upload error:', err);
                            setError('Failed to upload thumbnail. Please try again.');
                            setUploading(false);
                            reject(err);
                        },
                        async () => {
                            thumbnailUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve();
                        }
                    );
                });
            } catch (error) {
                console.error("Error uploading thumbnail:", error);
                setError("Failed to upload thumbnail. Please try again.");
                setUploading(false);
                return;
            }
        }

        if (thumbnailUrl) {
            pollData.thumbnailUrl = thumbnailUrl;
        }

        try {
            if (pollToEdit) {
                await updateDoc(doc(db, "polls", pollToEdit.id), pollData);
            } else {
                await addDoc(collection(db, "polls"), pollData);
            }
            setTitle('');
            setQuestions([]);
            setExpires('');
            setThumbnail(null);
            setError('');
            setUploading(false);
            onPollCreated();
        } catch (error) {
            setError("Failed to save poll. Please try again.");
            console.error("Error saving poll:", error);
            setUploading(false);
        }
    };

    return (
        <Container className="poll-create-container">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Naslov ankete</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter poll title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Enota</Form.Label>
                            <Form.Control
                                as="select"
                                value={unit}
                                onChange={handleUnitChange}
                            >
                                <option value="general">Splošno</option>
                                {units.map(u => (
                                    <option key={u.id} value={u.id}>{u.unitName}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {/* Thumbnail Upload */}
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Ikona ankete (thumbnail)</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleThumbnailChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="questions">
                        {(provided) => (
                            <Accordion ref={provided.innerRef} {...provided.droppableProps}>
                                {questions.map((q, qIndex) => (
                                    <Draggable key={qIndex} draggableId={`question-${qIndex}`} index={qIndex}>
                                        {(provided) => (
                                            <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mb-3">
                                                <Accordion.Header>
                                                    Vprašanje {qIndex + 1}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Form.Group>
                                                        <Form.Label>Vprašanje</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Vnesite vprašanje"
                                                            value={q.question}
                                                            onChange={(e) => handleQuestionChange(qIndex, 'question', e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Tip</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            value={q.type}
                                                            onChange={(e) => handleQuestionChange(qIndex, 'type', e.target.value)}
                                                        >
                                                            <option value="radio">En odgovor (Radio)</option>
                                                            <option value="checkbox">Več odgovorov (Checkbox)</option>
                                                            <option value="text">Tekst</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    {q.type !== 'text' && q.options.map((option, oIndex) => (
                                                        <InputGroup key={oIndex} className="mb-3">
                                                            <FormControl
                                                                placeholder={`Option ${oIndex + 1}`}
                                                                value={option}
                                                                onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    ))}
                                                    {q.type !== 'text' && (
                                                        <Button onClick={() => addOption(qIndex)} variant="secondary" className="mb-3">Dodaj opcijo</Button>
                                                    )}
                                                    <Form.Group className="mt-2">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Allow custom answer"
                                                            checked={q.allowCustomAnswer}
                                                            onChange={(e) => handleQuestionChange(qIndex, 'allowCustomAnswer', e.target.checked)}
                                                        />
                                                    </Form.Group>
                                                    <Button variant="danger" onClick={() => removeQuestion(qIndex)} className="mt-2">Odstrani vprašanje</Button>
                                                </Accordion.Body>
                                            </Card>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Accordion>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button onClick={addQuestion} variant="secondary" className="mb-3">Dodaj vprašanje</Button>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Poteče</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                onKeyDown={(e) => e.preventDefault()}
                                value={expires}
                                onChange={(e) => setExpires(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label="Pokaži rezultate"
                        checked={showResultsAfterVote}
                        onChange={(e) => setShowResultsAfterVote(e.target.checked)}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label="Anonimna anketa"
                        checked={anonymous}
                        onChange={(e) => setAnonymous(e.target.checked)}
                    />
                </Form.Group>

                {uploading && (
                    <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} striped />
                )}
                <Button variant="danger" type="submit" className="mb-3" disabled={uploading}>
                    {pollToEdit ? 'Posodobi anketo' : 'Ustvari anketo'}
                </Button>
            </Form>
        </Container>
    );
}

export default PollCreateForm;
