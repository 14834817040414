import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import BenefitCreate from './BenefitCreate';
import BenefitList from './BenefitList';
import BenefitDetail from './BenefitDetail';
import { useUser } from '../../context/UserContext';
import './Benefits.css';

function Benefits() {
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [showCreateBenefit, setShowCreateBenefit] = useState(false);
  const [editingBenefit, setEditingBenefit] = useState(null);

  const { user } = useUser();
  const isAdmin = user?.role === 'admin';
  const isLocalAdmin = user?.role === 'local-admin';

  const handleSelectBenefit = (benefit) => {
    setSelectedBenefit(benefit);
    setShowCreateBenefit(false);
    setEditingBenefit(null);
  };

  const handleBack = () => {
    setSelectedBenefit(null);
    setShowCreateBenefit(false);
    setEditingBenefit(null);
  };

  const toggleCreateBenefitForm = () => {
    setShowCreateBenefit(!showCreateBenefit);
    setSelectedBenefit(null);
    setEditingBenefit(null);
  };

  const handleBenefitCreated = () => {
    setShowCreateBenefit(false);
    setSelectedBenefit(null);
  };

  const handleEditBenefit = (benefit) => {
    setEditingBenefit(benefit);
    setShowCreateBenefit(false);
    setSelectedBenefit(null);
  };

  const handleBenefitUpdated = () => {
    setEditingBenefit(null);
    setSelectedBenefit(null);
  };

  return (
    <div className="full-bg">
      <Container className="benefits-container mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1 className="benefits-title">Ugodnosti</h1>
          </Col>
          <Col className="text-end">
            {!selectedBenefit && !editingBenefit && isAdmin && (
              <Button variant={showCreateBenefit ? "secondary" : "danger"} onClick={toggleCreateBenefitForm}>
                {showCreateBenefit ? 'Nazaj na izbiro' : 'Ustvari ugodnost'}
              </Button>
            )}
            {(selectedBenefit || editingBenefit) && (
              <Button variant="secondary" onClick={handleBack}>Nazaj na izbiro</Button>
            )}
          </Col>
        </Row>
        {!selectedBenefit && !editingBenefit ? (
          showCreateBenefit ? (
            <BenefitCreate onBenefitCreated={handleBenefitCreated} />
          ) : (
            <BenefitList
              onSelectBenefit={handleSelectBenefit}
              isAdmin={isAdmin}
              isLocalAdmin={isLocalAdmin}
              onEditBenefit={handleEditBenefit}
            />
          )
        ) : editingBenefit ? (
          <BenefitCreate
            onBenefitCreated={handleBenefitCreated}
            benefit={editingBenefit}
            onBenefitUpdated={handleBenefitUpdated}
          />
        ) : (
          <BenefitDetail benefit={selectedBenefit} />
        )}
      </Container>
    </div>
  );
}

export default Benefits;
