import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import ContactCreate from './ContactCreate';
import ContactList from './ContactList';
import { useUser } from '../../context/UserContext';
import './Contact.css';

function Contacts() {
  const [selectedContact, setSelectedContact] = useState(null);
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [contactToEdit, setContactToEdit] = useState(null); // State for editing contact

  const { user } = useUser();

  const handleSelectContact = (contact) => {
    setSelectedContact(contact);
    setShowCreateContact(false);
    setContactToEdit(null); // Clear the edit state when selecting a contact
  };

  const handleBack = () => {
    setSelectedContact(null);
    setShowCreateContact(false);
    setContactToEdit(null); // Clear the edit state when navigating back
  };

  const toggleCreateContactForm = () => {
    setShowCreateContact(!showCreateContact);
    setSelectedContact(null);
    setContactToEdit(null); // Clear the edit state when toggling the form
  };

  const handleContactCreated = () => {
    setShowCreateContact(false);
    setSelectedContact(null);
    setContactToEdit(null); // Clear the edit state after creating a contact
  };

  const handleEditContact = (contact) => {
    setContactToEdit(contact); // Set the contact to be edited
    setShowCreateContact(true); // Show the contact create form in edit mode
    setSelectedContact(null);
  };

  return (
    <div className="full-bg">
      <Container className="contacts-container mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1 className="contacts-title">Kontakti</h1>
          </Col>
          <Col className="text-end">
            {!selectedContact && user && user.role === 'admin' && (
              <Button variant={showCreateContact ? "secondary" : "danger"} onClick={toggleCreateContactForm}>
                {showCreateContact ? 'Nazaj' : 'Dodaj kontakt'}
              </Button>
            )}
            {selectedContact && (
              <Button variant="secondary" onClick={handleBack}>Nazaj</Button>
            )}
          </Col>
        </Row>
        {!selectedContact ? (
          <>
            {showCreateContact ? (
              <ContactCreate onContactCreated={handleContactCreated} contactToEdit={contactToEdit} />
            ) : (
              <ContactList
                onSelectContact={handleSelectContact}
                isAdmin={user && user.role === "admin"}
                onEditContact={handleEditContact} // Pass the edit handler to the list
              />
            )}
          </>
        ) : (
          // Placeholder for ContactDetail (if needed in future)
          null
        )}
      </Container>
    </div>
  );
}

export default Contacts;
